

div.wrapper-data {
    display:flex;
    flex-direction:row;
    white-space:nowrap;
    padding-left:10px;
    padding-right:10px;
}

p.ticker-data {
    font-size:24px;
    padding-top:3px;
    padding-bottom:3px;
}

.ticker-data {

    color: white;
    white-space:nowrap;
    font-weight:bold;
    padding-left:4px;
    padding-right:4px;
    margin-top:10px;
    margin-bottom:10px;
}

.ticker-data-red {
    color: red;
    white-space:nowrap;
    font-weight:bold;
    padding-left:4px;
    padding-right:4px;
}

.ticker-data-green {

    color: green;
    white-space:nowrap;
    font-weight:bold;
    padding-left:4px;
    padding-right:4px;
}