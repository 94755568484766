.ReactTable {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color:black;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ReactTable * {
  box-sizing: border-box
}

td {
  color:black;
}

th {
  color: black;
}

tr.even {
  background-color: rgb(226, 225, 225)
}
thead tr:first-child {
  background-color: rgb(189, 189, 189);

}


table > thead > tr:nth-child(1) {
  font-size: 18px;
}

.ReactTable .rt-thead {
  overflow-y: scroll;
}