
body {
    color:gray;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}

div.tournament-list-wrapper {
    font-size: 32px;
    padding:10px;

};
